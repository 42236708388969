import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Cliente from "./pages/Cliente/Cliente"; // Ruta para seleccionar cliente
import Page1 from "./pages/Page1/Page1";  // Página para mostrar el cliente
import Page2 from "./pages/Page2/Page2";  // Página para los detalles
import GeoMarca from "./pages/GeoMarca/geoMarca";  // Página para los detalles

import Login from "./pages/Login/login"; // Login

function App() {
  return (
    <Router>
      <Routes>
        {/* Ruta para el Login */}
        <Route path="/" element={<Login />} />

        {/* Ruta para seleccionar cliente */}
        <Route
          path="/cliente"
          element={
            <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
              <div className="layout-container">
                <Navbar />
                <Sidebar />
                <div className="layout-page">
                  <div className="content-wrapper">
                    <Cliente /> {/* Componente Cliente donde se selecciona */}
                  </div>
                </div>
              </div>
            </div>
          }
        />

        {/* Ruta para la selección del cliente - Page 1 */}
        <Route
          path="/page-1"
          element={
            <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
              <div className="layout-container">
                <Navbar />
                <Sidebar />
                <div className="layout-page">
                  <div className="content-wrapper">
                    <Page1 /> {/* Muestra la información del cliente seleccionado */}
                  </div>
                </div>
              </div>
            </div>
          }
        />

        {/* Ruta para Page 2 */}
        <Route
          path="/page-2"
          element={
            <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
              <div className="layout-container">
                <Navbar />
                <Sidebar />
                <div className="layout-page">
                  <div className="content-wrapper">
                    <Page2 /> {/* Muestra detalles del cliente */}
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <Route
          path="/geomarca"
          element={
            <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
              <div className="layout-container">
                <Navbar />
                <Sidebar />
                <div className="layout-page">
                  <div className="content-wrapper">
                    <GeoMarca /> {/* Muestra detalles del cliente */}
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
