import React, { useEffect, useState } from "react";
import { centerMapOnFleet } from "./mapActions"; // Asegúrate de importar correctamente
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* global google */
const Page2 = () => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState({});
  const initialCenter = { lat: -2.17, lng: -79.9 }; // Centro inicial del mapa
  const initialZoom = 12; // Zoom inicial del mapa
  const [mapStatus, setMapStatus] = useState(false); // true = Activo, false = Inactivo
  const [polygons, setPolygons] = useState([]); // Almacena los polígonos en el mapa

  const [selectedData, setSelectedData] = useState(null); // Datos del registro seleccionado
  const [isModalVisible, setIsModalVisible] = useState(false); // Estado del modal

  // Función para abrir el modal
  const handleViewDetails = async (data) => {
    if (data.secondaryMarkers && data.secondaryMarkers.length > 0) {
      const names = await getPlaceNames(data.secondaryMarkers);
      const updatedMarkers = data.secondaryMarkers.map((marker, index) => ({
        ...marker,
        name: names[index],
      }));
      setSelectedData({ ...data, secondaryMarkers: updatedMarkers });
    } else {
      setSelectedData(data); // Si no hay secondaryMarkers, no hay que procesar
    }
    setIsModalVisible(true); // Mostrar el modal
  };
  

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setSelectedData(null); // Limpiar los datos seleccionados
    setIsModalVisible(false); // Ocultar el modal
  };

  // Función para centrar el mapa en las coordenadas del registro seleccionado
  const handleViewOnMap = () => {
    if (selectedData && map) {
      const { lat, lng } = selectedData;
      map.panTo({ lat, lng }); // Centrar el mapa en las coordenadas
      setIsModalVisible(false); // Cerrar el modal
    }
  };

  // Estado para almacenar los datos recuperados de sessionStorage
  const [savedData, setSavedData] = useState([]);

  useEffect(() => {
    // Verificar si la geolocalización está disponible
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          console.log("Ubicación inicial del usuario:", userLocation);

          if (map && savedData.length > 0) {
            // Crear marcador para la ubicación inicial
            new google.maps.Marker({
              position: userLocation,
              map: map,
              title: "Tu ubicación actual",
            });

            // Crear una copia de los puntos guardados
            let remainingPoints = [...savedData];
            let currentLocation = userLocation;

            const directionsService = new google.maps.DirectionsService();

            const calculateNextRoute = () => {
              if (remainingPoints.length === 0) {
                console.log("Todas las rutas han sido calculadas y pintadas.");
                return; // Finalizamos cuando no quedan puntos por procesar
              }

              // Encontrar el punto más cercano entre los puntos restantes
              const closestPointIndex = remainingPoints.reduce(
                (closestIndex, current, index, array) => {
                  const distanceToCurrent = haversineDistance(currentLocation, {
                    lat: current.lat,
                    lng: current.lng,
                  });

                  const distanceToClosest = haversineDistance(currentLocation, {
                    lat: array[closestIndex].lat,
                    lng: array[closestIndex].lng,
                  });

                  return distanceToCurrent < distanceToClosest
                    ? index
                    : closestIndex;
                },
                0
              );

              const closestPoint = remainingPoints[closestPointIndex];
              console.log("Punto más cercano encontrado:", closestPoint);

              // Generar la ruta al punto más cercano
              directionsService.route(
                {
                  origin: currentLocation,
                  destination: { lat: closestPoint.lat, lng: closestPoint.lng },
                  travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === google.maps.DirectionsStatus.OK) {
                    // Crear una nueva instancia de DirectionsRenderer para mantener la ruta pintada
                    const directionsRenderer =
                      new google.maps.DirectionsRenderer({
                        map: map,
                        preserveViewport: true, // Mantener el nivel de zoom y posición del mapa
                      });

                    directionsRenderer.setDirections(result);

                    // Actualizar la ubicación actual
                    currentLocation = {
                      lat: closestPoint.lat,
                      lng: closestPoint.lng,
                    };

                    // Eliminar el punto visitado de la lista
                    remainingPoints.splice(closestPointIndex, 1);

                    // Calcular la siguiente ruta después de un pequeño retraso
                    setTimeout(calculateNextRoute, 1000);
                  } else {
                    console.error("Error al generar la ruta:", status);
                  }
                }
              );
            };

            // Iniciar el cálculo de rutas
            calculateNextRoute();
          }
        },
        (error) => {
          console.error("Error obteniendo la ubicación:", error.message);
        }
      );
    } else {
      console.error("La geolocalización no está soportada por este navegador.");
    }
  }, [map, savedData]);

  const handleSwitchChange = () => {
    const newStatus = !mapStatus;
    setMapStatus(newStatus);

    if (newStatus) {
      // Si el switch se activa, pintar las zonas en el mapa
      const savedZones =
        JSON.parse(localStorage.getItem("zonasGuardadas")) || [];
      console.log("Pintando zonas guardadas en el mapa:", savedZones);

      const newPolygons = savedZones.map((zone) => {
        let fillColor;
        switch (zone.nivelSeleccionado) {
          case "ALTO":
            fillColor = "red";
            break;
          case "MEDIO":
            fillColor = "#FFB199"; // Color más claro para MEDIO
            break;
          case "BAJO":
            fillColor = "green";
            break;
          default:
            fillColor = "gray";
        }

        const polygon = new google.maps.Polygon({
          paths: zone.coordenadas,
          strokeOpacity: 0,
          fillColor: fillColor,
          fillOpacity: 0.35,
          map: map,
        });

        return polygon; // Retornar el polígono creado
      });

      setPolygons(newPolygons); // Guardar los polígonos en el estado
    } else {
      // Si el switch está inactivo, limpiar los polígonos del mapa
      clearPolygons();
    }
  };

  const clearPolygons = () => {
    polygons.forEach((polygon) => polygon.setMap(null)); // Eliminar cada polígono del mapa
    setPolygons([]); // Vaciar el estado de polígonos
  };

  // Función para calcular la distancia Haversine
  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Radio de la Tierra en kilómetros

    const dLat = toRad(coords2.lat - coords1.lat);
    const dLon = toRad(coords2.lng - coords1.lng);

    const lat1 = toRad(coords1.lat);
    const lat2 = toRad(coords2.lat);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distancia en kilómetros
  };

  useEffect(() => {
    // Obtener los datos almacenados en sessionStorage
    const savedDataFromStorage = sessionStorage.getItem("savedData");

    if (savedDataFromStorage) {
      // Si los datos existen, parsearlos y guardarlos en el estado
      const parsedData = JSON.parse(savedDataFromStorage);
      console.log("Datos parseados:", parsedData);
      setSavedData(parsedData);
    } else {
      console.log("No hay datos almacenados.");
    }
  }, []); // Solo se ejecuta una vez cuando el componente se monta

  useEffect(() => {
    const google = window.google;

    // Inicializar el mapa
    const mapInstance = new google.maps.Map(document.getElementById("map"), {
      center: initialCenter,
      zoom: initialZoom,
    });

    setMap(mapInstance);

    // Crear marcadores iniciales si hay datos en savedData
  }, [savedData]);

  // Función para manejar el clic en los botones de las flotas y hacer zoom
  const handleFleetClick = (fleetId, isOpen) => {
    const data = savedData.find((item) => item.clientId === fleetId); // Buscar la flota por su ID
    if (!map || !data) return;

    const fleetCoordinates = { lat: data.lat, lng: data.lng }; // Obtener las coordenadas de la flota

    if (isOpen) {
      centerMapOnFleet(map, fleetCoordinates); // Centrar el mapa en la flota
      smoothZoom(map, 15); // Realizar un zoom con transición
    } else {
      map.panTo(initialCenter);
      smoothZoom(map, initialZoom); // Hacer zoom out con transición
    }
  };

  // Función para hacer el zoom suave
  const smoothZoom = (mapInstance, targetZoom) => {
    const currentZoom = mapInstance.getZoom();
    const step = targetZoom > currentZoom ? 1 : -1;

    const zoomAnimation = () => {
      const nextZoom = mapInstance.getZoom() + step;

      if (
        (step > 0 && nextZoom <= targetZoom) ||
        (step < 0 && nextZoom >= targetZoom)
      ) {
        mapInstance.setZoom(nextZoom);
        setTimeout(zoomAnimation, 300);
      }
    };

    zoomAnimation();
  };

  const handleDelete = (index) => {
    // Eliminar el elemento del estado
    const updatedSavedData = savedData.filter((_, i) => i !== index);
    setSavedData(updatedSavedData);

    // Actualizar el sessionStorage
    sessionStorage.setItem("savedData", JSON.stringify(updatedSavedData));
  };

  
  const getPlaceNames = async (markers) => {
    const geocoder = new google.maps.Geocoder();
    const results = await Promise.all(
      markers.map(
        (marker) =>
          new Promise((resolve) => {
            geocoder.geocode({ location: { lat: marker.lat, lng: marker.lng } }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK && results[0]) {
                resolve(results[0].formatted_address); // Obtiene la dirección formateada
              } else {
                resolve("Nombre no disponible");
              }
            });
          })
      )
    );
    return results;
  };
  

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        {/* Información del Fleet */}
        <div className="col-md-4">
          <div className="card">
            <div className="card-header">Información de visitas</div>
            <div className="card-body">
              <div className="accordion" id="fleetAccordion">
                {savedData.map((data, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`headingFleet${index}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseFleet${index}`}
                        aria-expanded="false"
                        aria-controls={`collapseFleet${index}`}
                        onClick={(e) =>
                          handleFleetClick(
                            data.clientId,
                            e.target.getAttribute("aria-expanded") === "true"
                          )
                        }
                      >
                        {data.clientName} - {data.provinceName}, {data.cityName}
                      </button>
                    </h2>
                    <div
                      id={`collapseFleet${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`headingFleet${index}`}
                      data-bs-parent="#fleetAccordion"
                    >
                      <div className="accordion-body">
                        <h6 className="font-weight-bold">Gestion</h6>
                        <div className="mb-2">
                          <span className="font-weight-bold">Débito total:</span>
                          <span className="text-body"> {data.debtTotal}$</span>
                        </div>
                        <div className="mb-2">
                          <span className="font-weight-bold">Capital Balance:</span>
                          <span className="text-body">{data.capitalBalance}</span>
                        </div>
                        <div className="mb-2">
                          <span className="font-weight-bold">Dirección:</span>
                          <span className="text-body">{data.address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
  
        {/* Switch para mostrar el estado */}
        <div className="col-md-8">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span>
              Ver Geomarca: <strong>{mapStatus ? "Activo" : "Inactivo"}</strong>
            </span>
            <label className="switch">
              <input
                type="checkbox"
                checked={mapStatus}
                onChange={handleSwitchChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
  
          {/* Contenedor del Mapa */}
          <div id="map" style={{ width: "100%", height: "500px" }}></div>
        </div>
  
        {/* DataTable Section */}
        <div className="container-xxl flex-grow-1 container-p-y mt-4">
          <div className="card">
            <div className="card-datatable table-responsive">
              <table className="table border-top">
                <thead>
                  <tr>
                    <th>Identidad</th>
                    <th>Nombre</th>
                    <th>Provincia</th>
                    <th>Ciudad</th>
                    <th>Dirección</th>
                    <th>Fecha proyectada</th>
                    <th>Deuda Total</th>
                    <th>Saldo Capital</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {savedData.map((data, index) => (
                    <tr key={index}>
                      <td>{data.clientId}</td>
                      <td>{data.clientName}</td>
                      <td>{data.provinceName}</td>
                      <td>{data.cityName}</td>
                      <td>{data.address}</td>
                      <td>{data.projectedDate}</td>
                      <td>{data.debtTotal}</td>
                      <td>{data.capitalBalance}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => handleViewDetails(data)}
                        >
                          <span className="ms-1">Ver</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  
      {/* Modal */}
      {isModalVisible && (
  <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Detalles del Registro</h5>
          <button type="button" className="btn-close" onClick={handleCloseModal}></button>
        </div>
        <div className="modal-body">
          {selectedData && (
            <div>
              <p><strong>Identidad:</strong> {selectedData.clientId}</p>
              <p><strong>Nombre:</strong> {selectedData.clientName}</p>
              <p><strong>Provincia:</strong> {selectedData.provinceName}</p>
              <p><strong>Ciudad:</strong> {selectedData.cityName}</p>
              <p><strong>Dirección:</strong> {selectedData.address}</p>
              <p><strong>Fecha proyectada:</strong> {selectedData.projectedDate}</p>
              <p><strong>Deuda Total:</strong> {selectedData.debtTotal}</p>
              <p><strong>Saldo Capital:</strong> {selectedData.capitalBalance}</p>

              {/* Lista de nombres de marcadores secundarios */}
              {selectedData.secondaryMarkers && selectedData.secondaryMarkers.length > 0 && (
                <div>
                  <p><strong>Marcadores Secundarios:</strong></p>
                  <ul>
                    {selectedData.secondaryMarkers.map((marker, index) => (
                      <li key={index}>{marker.name || "Nombre no disponible"}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button type="button" className="btn btn-primary" onClick={handleViewOnMap}>
            Ver en Mapa
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
  
};

export default Page2;
