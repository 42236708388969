import React, { useEffect, useRef } from "react";

const MapWithClick = ({ onMapClick, onSecondaryMapClick, coordinates, secondaryMarkers }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markerRef = useRef(null);
  const geocoder = useRef(null);

  useEffect(() => {
    const google = window.google;
    const initialCenter = { lat: -2.17, lng: -79.9 }; // Centro inicial
    const initialZoom = 8; // Zoom inicial

    if (!mapInstanceRef.current) {
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: initialCenter,
        zoom: initialZoom,
      });
      geocoder.current = new google.maps.Geocoder();
    }

    const mapInstance = mapInstanceRef.current;

    // Evento de clic izquierdo para obtener las coordenadas principales
    mapInstance.addListener("click", (e) => {
      const latLng = e.latLng;

      // Eliminar marcador anterior
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }

      // Agregar marcador principal (de color predeterminado)
      markerRef.current = new google.maps.Marker({
        position: latLng,
        map: mapInstance,
      });

      // Obtener la dirección
      geocoder.current.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          const address = results[0].formatted_address;
          if (onMapClick) {
            onMapClick({
              lat: latLng.lat(),
              lng: latLng.lng(),
              address,
            });
          }
        }
      });
    });

    // Evento de clic derecho para agregar nuevos marcadores
    mapInstance.addListener("rightclick", (e) => {
      const latLng = e.latLng;

      // Crear un nuevo marcador secundario de color azul en el mapa
      const newMarker = new google.maps.Marker({
        position: latLng,
        map: mapInstance,
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Marcador azul
        },
      });

      // Llamar a la función onSecondaryMapClick para agregar el marcador a la lista en Page1
      onSecondaryMapClick({ lat: latLng.lat(), lng: latLng.lng() });
    });

    mapInstance.setOptions({ disableDoubleClickZoom: true });
  }, [onSecondaryMapClick]);

  // Actualizar el mapa y el marcador principal al cambiar las coordenadas
  useEffect(() => {
    if (coordinates && coordinates.lat && coordinates.lng && mapInstanceRef.current) {
      const google = window.google;
      const mapInstance = mapInstanceRef.current;

      // Centrar el mapa en las nuevas coordenadas
      mapInstance.setCenter({ lat: coordinates.lat, lng: coordinates.lng });

      // Mover el marcador principal a las nuevas coordenadas
      if (!markerRef.current) {
        markerRef.current = new google.maps.Marker({
          position: { lat: coordinates.lat, lng: coordinates.lng },
          map: mapInstance,
        });
      } else {
        markerRef.current.setPosition({ lat: coordinates.lat, lng: coordinates.lng });
      }
    }
  }, [coordinates]);

  return (
    <div
      id="map"
      ref={mapRef}
      style={{ width: "100%", height: "400px" }}
    ></div>
  );
};

export default MapWithClick;
