export const centerMapOnFleet = (map, fleetCoordinates, zoom = 14) => {
  if (!map || !fleetCoordinates) return;

  // Centrar el mapa en las coordenadas proporcionadas
  map.setCenter(fleetCoordinates);

  // Cambiar el nivel de zoom
  map.setZoom(zoom);
};

export const addMarker = (map, fleetCoordinates) => {
  if (!map || !fleetCoordinates) return;

  // Crear un nuevo marcador
  new window.google.maps.Marker({
    position: fleetCoordinates,
    map: map,
  });
};
export const drawPolygonZone = (map, points) => {
  if (!map || points.length < 4) {
    console.error("No se proporcionaron suficientes puntos para el polígono");
    return; // Asegurarse de que haya al menos 4 puntos
  }

  // Verificar los puntos recibidos en consola
  console.log("Puntos del polígono:", points);

  // Crear un array de LatLng de los puntos asegurándonos de que sean números
  const path = points.map((point) => {
    const lat = parseFloat(point.lat);  // Asegurarse de que lat sea un número
    const lng = parseFloat(point.lng);  // Asegurarse de que lng sea un número
    console.log("Coordenada LatLng:", lat, lng);
    return new window.google.maps.LatLng(lat, lng);  // Crear el objeto LatLng
  });

  console.log("Coordenadas LatLng del polígono:", path);

  // Dibujar el polígono (área cerrada)
  const polygon = new window.google.maps.Polygon({
    paths: path,
    strokeColor: "#FF0000", // Rojo
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000", // Rojo
    fillOpacity: 0.35,
    map: map,
  });

  console.log("Polígono creado:", polygon);

  // Opcional: Ajustar el mapa a los límites del polígono
  const bounds = new window.google.maps.LatLngBounds();
  path.forEach((point) => bounds.extend(point)); // Establecer los límites según los puntos del polígono
  console.log("Límites del polígono establecidos:", bounds);

  map.fitBounds(bounds); // Ajustar el mapa para ver toda el área
};





// Nueva función para agregar un círculo alrededor de una zona en el mapa sin interferir con los marcadores
export const addCircleZone = (map, fleetCoordinates, radius = 1000) => {
  if (!map || !fleetCoordinates) return;

  // Crear un círculo en el mapa para representar la zona
  new window.google.maps.Circle({
    strokeColor: "#FF0000", // Rojo
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000", // Rojo
    fillOpacity: 0.35,
    map: map,
    center: fleetCoordinates,
    radius: radius, // Radio en metros
  });
};
