import axios from "axios";

// Función para hacer login
export const login = async (usuario, clave, cedente) => {
  try {
    const config = {
      method: "POST",
      url: "/sgcc_campo/general/c_general/sp_valida_usuario",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        usuario: usuario,
        clave: clave,
        cedente: cedente,
      }),
    };

    const response = await axios(config);

    // Verificar si la respuesta contiene datos válidos
    if (response.data && Array.isArray(response.data) && response.data.length > 0) {
      const user = response.data[0]; // Obtenemos el primer objeto de la respuesta
      // Guardamos los datos en sessionStorage
      sessionStorage.setItem("sessionId", user.usuario);
      sessionStorage.setItem("nombre", user.nombre);
      sessionStorage.setItem("grupo_comprada", user.grupo_comprada);
      sessionStorage.setItem("cedente", cedente);

      return response.data; // Retorna la respuesta de la API
    } else {
      // Si no hay datos válidos, podemos lanzar un error o devolver null
      console.error("Usuario no encontrado o acceso denegado.");
      return null;
    }
  } catch (error) {
    console.error("Error al hacer login:", error);
    throw error;
  }
};



// Obtener cedentes
export const fetchCedentes = async () => {
  try {
    const config = {
      method: "POST",
      url: "https://api.procrearec.com/", // Endpoint proporcionado
      headers: {
        "Cookie": "PHPSESSID=rv8tvqfhnkhoubiaeuvp0422u7; ci_session=a%3A5%3A%7Bs%3A10%3A%22session_id%22%3Bs%3A32%3A%227b47e6732a5ec59c3c116148623c8906%22%3Bs%3A10%3A%22ip_address%22%3Bs%3A11%3A%22192.168.1.1%22%3Bs%3A10%3A%22user_agent%22%3Bs%3A21%3A%22PostmanRuntime%2F7.42.0%22%3Bs%3A13%3A%22last_activity%22%3Bi%3A1732644623%3Bs%3A9%3A%22user_data%22%3Bs%3A0%3A%22%22%3B%7D13c6b9166caae8a83506484a22180bf9fae44376",
      },
    };

    const response = await axios(config);
    return response.data; // Devuelve los datos de los cedentes
  } catch (error) {
    console.error("Error fetching cedentes:", error);
    throw error;
  }
};
