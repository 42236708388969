import axios from "axios";

// Obtener provincias
export const fetchProvinces = async () => {
  try {
    const config = {
      method: "POST",
      url: "/sgcc_campo/general/c_general/sp_retProvincia", // Usamos una ruta relativa gracias al proxy
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        tipo: "P", // Tipo para provincias
        provincia: "",
      }),
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching provinces:", error);
    throw error;
  }
};

// Obtener ciudades
export const fetchCities = async (provinceId) => {
  try {
    const config = {
      method: "POST",
      url: "/sgcc_campo/general/c_general/sp_retProvincia", // Usamos una ruta relativa gracias al proxy
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        tipo: "C", // Tipo para ciudades
        provincia: provinceId, // Enviamos el identificador de la provincia
      }),
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};


export const fetchClientData = async (provincia = "T", ciudad = "T") => {
  try {
    const cedente = sessionStorage.getItem("cedente"); // Obtiene el cedente desde sessionStorage
    const usuario = sessionStorage.getItem("sessionId"); // Obtiene el nombre del usuario desde sessionStorage
    
    if (!cedente || !usuario) {
      throw new Error("Cedente o usuario no encontrados en sessionStorage");
    }

    const config = {
      method: "POST",
      url: "/sgcc_campo/general/c_general/sp_retAsignacionGestor",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams({
        cedente, // Utiliza el cedente obtenido de sessionStorage
        usuario, // Utiliza el nombre del usuario obtenido de sessionStorage
        provincia, // Parámetro dinámico para provincia
        ciudad, // Parámetro dinámico para ciudad
      }),
    };

    const response = await axios(config);
    return [response.data]; // Devuelve un arreglo con los datos del cliente
  } catch (error) {
    console.error("Error fetching client data:", error);
    throw error;
  }
};




