import React, { useState, useEffect, useRef } from "react";
import { fetchProvinces, fetchCities, fetchClientData } from "./api"; // Importa la función de clientes
import MapWithClick from "./MapWithClick";
import { useLocation } from "react-router-dom"; // Importa useLocation para obtener los datos
import Swal from "sweetalert2";

const Page1 = () => {
  const location = useLocation();
  const [clientData, setClientData] = useState(
    location.state?.clientData || {}
  );
  const [provinceCode, setProvinceCode] = useState(
    location.state?.provinceCode || ""
  );
  const [provinceName, setProvinceName] = useState(
    location.state?.provinceName || ""
  );
  const [cityCode, setCityCode] = useState(location.state?.cityCode || "");
  const [cityName, setCityName] = useState(location.state?.cityName || "");

  const [locationData, setLocationData] = useState({
    lat: null,
    lng: null,
    address: "",
  });

  const textareaRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const [secondaryMarkers, setSecondaryMarkers] = useState([]); // Almacenar coordenadas secundarias
  const [savedData, setSavedData] = useState([]); // Para almacenar la lista de información guardada
  const [showSecondaryCoordinates, setShowSecondaryCoordinates] =
    useState(false); // Control de visibilidad

  const [selectedDate, setSelectedDate] = useState("");

  const flatpickrInstance = useRef(null); // Referencia para la instancia de Flatpickr

  useEffect(() => {
    if (clientData) {
      console.log("Datos del cliente:", clientData);
    }
  }, [clientData]);

  useEffect(() => {
    // Recuperar los datos de sessionStorage si existen
    const savedDataFromStorage = sessionStorage.getItem("savedData");
    if (savedDataFromStorage) {
      // Parsear los datos guardados en sessionStorage y asignarlos a savedData
      const parsedData = JSON.parse(savedDataFromStorage);
      setSavedData(parsedData);
      console.log("Datos recuperados de sessionStorage:", parsedData);
    }
  }, []); // Solo se ejecuta una vez cuando el componente se monta

  useEffect(() => {
    const initializeFlatpickr = () => {
      const flatpickrElement = document.getElementById("dateSelect");

      if (!flatpickrElement) {
        console.error("El elemento con ID 'dateSelect' no existe.");
        return;
      }

      // Si existe una instancia previa, destrúyela antes de crear una nueva
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy();
        flatpickrInstance.current = null;
      }

      // Crear una nueva instancia de Flatpickr
      flatpickrInstance.current = window.flatpickr(flatpickrElement, {
        dateFormat: "Y-m-d",
        onChange: (selectedDates, dateStr) => {
          setSelectedDate(dateStr); // Actualiza la fecha seleccionada
        },
      });

      console.log("Flatpickr inicializado correctamente.");
    };

    initializeFlatpickr(); // Inicializar al montar el componente

    // Limpiar al desmontar el componente
    return () => {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy();
        flatpickrInstance.current = null;
        console.log("Flatpickr destruido.");
      }
    };
  }, []);

  useEffect(() => {
    if (window.google && textareaRef.current) {
      console.log("Inicializando Autocomplete...");

      const autoCompleteInstance = new window.google.maps.places.Autocomplete(
        textareaRef.current,
        {
          types: ["geocode"], // Opcional: Limita a direcciones
          componentRestrictions: { country: "ec" }, // Opcional: Restringe a un país (Ecuador)
        }
      );

      console.log("Autocomplete inicializado:", autoCompleteInstance);

      autoCompleteInstance.addListener("place_changed", () => {
        const place = autoCompleteInstance.getPlace();
        console.log("Lugar seleccionado:", place);

        if (place.geometry) {
          const { lat, lng } = place.geometry.location;
          const address = place.formatted_address;

          console.log("Geometría obtenida:", {
            lat: lat(),
            lng: lng(),
            address,
          });

          // Actualizar las coordenadas y la dirección seleccionada
          setLocationData({
            address,
            lat: lat(),
            lng: lng(),
          });
        } else {
          console.warn("No se encontró geometría para este lugar.");
        }
      });

      return () => {
        console.log("Limpiando listeners de Autocomplete...");
        window.google.maps.event.clearInstanceListeners(autoCompleteInstance);
      };
    } else {
      console.error(
        "Google Maps no está disponible o el textarea no está configurado."
      );
    }
  }, []);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setLocationData((prev) => ({
      ...prev,
      address: newAddress,
    }));
  };

  const handleSave = () => {
    const today = new Date().toISOString().split("T")[0];
  
    // Obtener los datos del sessionStorage
    const sessionId = sessionStorage.getItem("sessionId");
    const nombre = sessionStorage.getItem("nombre");
    const grupoComprada = sessionStorage.getItem("grupo_comprada");
    const cedente = sessionStorage.getItem("cedente");
  
    // Eliminar marcadores duplicados en secondaryMarkers
    const uniqueSecondaryMarkers = secondaryMarkers.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (marker) => marker.lat === value.lat && marker.lng === value.lng // Comparar las coordenadas
        )
    );
  
    // Crear el objeto con los datos a guardar
    const dataToSave = {
      provinceCode: provinceCode, // Código de la provincia
      cityCode: cityCode, // Código de la ciudad
      provinceName: provinceName, // Nombre de la provincia
      cityName: cityName, // Nombre de la ciudad
      lat: locationData.lat, // Latitud
      lng: locationData.lng, // Longitud
      address: locationData.address, // Dirección
      clientId: clientData.identificacion, // ID del cliente
      clientName: clientData.nombre, // Nombre del cliente
      projectedDate: selectedDate, // Fecha proyectada
      todayDate: today, // Fecha de hoy
      debtTotal: clientData.deuda_total, // Deuda total
      capitalBalance: clientData.saldo_capital, // Saldo capital
      usuario: sessionId, // Agregar sessionId
      nombre: nombre, // Agregar nombre
      grupoComprada: grupoComprada, // Agregar grupo_comprada
      cedente: cedente, // Agregar cedente
      cedenteUser: clientData.cedente,
      secondaryMarkers: uniqueSecondaryMarkers, // Guardar los marcadores secundarios únicos
    };
  
    // Recuperar los datos existentes en sessionStorage
    const existingData = sessionStorage.getItem("savedData");
    let updatedSavedData = [];
  
    if (existingData) {
      // Si existen datos, parsearlos y agregar los nuevos sin duplicarlos
      updatedSavedData = JSON.parse(existingData);
      
      // Comprobar si el nuevo objeto ya está en los datos guardados (por ejemplo, usando `clientId` o una propiedad única)
      const isDuplicate = updatedSavedData.some(
        (item) => item.clientId === dataToSave.clientId
      );
      
      if (!isDuplicate) {
        updatedSavedData.push(dataToSave);
      } else {
        console.log("El dato ya existe en el almacenamiento.");
      }
    } else {
      // Si no existen datos, se crea un nuevo array
      updatedSavedData.push(dataToSave);
    }
  
    // Guardar los datos actualizados en sessionStorage
    sessionStorage.setItem("savedData", JSON.stringify(updatedSavedData));
  
    // Actualizar el estado de `savedData` para que la tabla se actualice
    setSavedData(updatedSavedData);
  
    // Mostrar los datos guardados en consola para verificar
    console.log("Json Guardado:", dataToSave);
  };
  
  

  const handleDelete = (index) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminarlo",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setSavedData((prevData) => prevData.filter((_, i) => i !== index));
        Swal.fire("Eliminado!", "El elemento ha sido eliminado.", "success");
      }
    });
  };

  // Función para manejar clic derecho y agregar coordenadas secundarias
  const handleSecondaryMapClick = (data) => {
    // Verificar si la coordenada secundaria ya existe en el arreglo
    const existingMarker = secondaryMarkers.find(
      (marker) => marker.lat === data.lat && marker.lng === data.lng
    );

    if (!existingMarker) {
      // Agregar coordenada secundaria solo si no existe
      setSecondaryMarkers((prevMarkers) => [...prevMarkers, data]);
    } else {
      console.log("Coordenada secundaria ya existe");
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h6>INGRESO DE DATOS PARA PROYECCIÓN DE VISITAS</h6>
      <div className="card mb-6">
        <div className="card-body">
          {/* Aquí va el formulario de selección */}
          <div className="row">
            {/* Fila con dos columnas */}
            <div className="row">
              {/* Columna con los datos del cliente (a la derecha) */}
              <div className="col-md-9">
                <h6>Cliente Seleccionado:</h6>
                <div className="row">
                  {/* Fila 1 con tres datos */}
                  <div className="col-md-4">
                    <p>Nombre: {clientData.nombre}</p>
                  </div>
                  <div className="col-md-4">
                    <p>Cedente: {clientData.cedente}</p>
                  </div>
                  <div className="col-md-4">
                    <p>Identificación: {clientData.identificacion}</p>
                  </div>
                </div>

                <div className="row">
                  {/* Fila 2 con tres datos */}
                  <div className="col-md-4">
                    <p>Deuda Total: {clientData.deuda_total}</p>
                  </div>
                  <div className="col-md-4">
                    <p>Saldo Capital: {clientData.saldo_capital}</p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      Provincia: {provinceName} (Código: {provinceCode})
                    </p>
                  </div>
                </div>

                <div className="row">
                  {/* Fila 3 con los últimos datos */}
                  <div className="col-md-4">
                    <p>
                      Ciudad: {cityName} (Código: {cityCode})
                    </p>
                  </div>
                </div>
              </div>

              {/* Columna con el selector de fecha (a la izquierda) */}
              <div className="col-md-3">
                <label htmlFor="dateSelect" className="form-label">
                  Selecciona una fecha
                </label>
                <div className="input-group">
                  <input
                    id="dateSelect"
                    className="form-control"
                    placeholder="Seleccionar fecha"
                    value={selectedDate}
                    readOnly
                  />
                  <button
                    type="button"
                    className="input-group-text btn"
                    onClick={() => {
                      if (flatpickrInstance.current) {
                        flatpickrInstance.current.open(); // Abre Flatpickr manualmente
                      } else {
                        console.error(
                          "Flatpickr no está inicializado correctamente. Intentando inicializar de nuevo..."
                        );
                        const flatpickrElement =
                          document.getElementById("dateSelect");
                        if (flatpickrElement) {
                          flatpickrInstance.current = window.flatpickr(
                            flatpickrElement,
                            {
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr) => {
                                setSelectedDate(dateStr); // Actualiza la fecha seleccionada
                              },
                            }
                          );
                          flatpickrInstance.current.open(); // Abre Flatpickr después de inicializarlo
                        }
                      }
                    }}
                  >
                    <i className="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Aquí va la parte del mapa y textarea */}
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="card-body">
              <div className="form-floating">
                <textarea
                  ref={textareaRef}
                  className="form-control"
                  id="floatingTextarea"
                  placeholder="Dirección"
                  aria-describedby="floatingTextareaHelp"
                  style={{
                    resize: "none",
                    overflow: "auto",
                    height: "120px",
                  }}
                  value={locationData.address}
                  onChange={handleAddressChange} // Manejador separado
                ></textarea>
                <label htmlFor="floatingTextarea">Dirección seleccionada</label>
              </div>
              <label className="form-label">Coordenadas seleccionadas:</label>
              <div className="mt-2">
                <label className="form-label">Coordenada principal:</label>
                <div>
                  {locationData.lat && locationData.lng
                    ? `Latitud: ${locationData.lat}, Longitud: ${locationData.lng}`
                    : "Haga clic en el mapa para obtener las coordenadas."}
                </div>
              </div>

              {/* Mostrar coordenadas secundarias */}
              <div
                className="mt-2"
                style={{ display: showSecondaryCoordinates ? "block" : "none" }}
              >
                <label className="form-label">Coordenadas secundarias:</label>
                <div>
                  {secondaryMarkers.length > 0 ? (
                    <ul>
                      {secondaryMarkers.map((data, index) => (
                        <li key={index}>
                          Latitud: {data.lat}, Longitud: {data.lng} - Dirección:{" "}
                          {data.address}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "Haga clic derecho en el mapa para obtener las coordenadas secundarias."
                  )}
                </div>
              </div>

              <div className="col d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave} // Función de guardar
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                <MapWithClick
                  coordinates={locationData} // Coordenadas actuales
                  onMapClick={(data) => {
                    setLocationData(data); // Actualiza las coordenadas y la dirección
                  }}
                  onSecondaryMapClick={handleSecondaryMapClick} // Agregar coordenadas secundarias
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              {savedData.length > 0 && (
                <div className="mt-4">
                  <h6 className="small" style={{ fontSize: "0.8rem" }}>
                    Ruta proyectada:
                  </h6>
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table className="table table-bordered table-sm small">
                      <thead>
                        <tr>
                          <th>Identidad</th>
                          <th>Nombre</th>
                          <th>Código Provincia</th>
                          <th>Provincia</th>
                          <th>Código Ciudad</th>
                          <th>Ciudad</th>
                          <th>Latitud</th>
                          <th>Longitud</th>
                          <th>Dirección</th>
                          <th>Fecha de hoy</th>
                          <th>Fecha proyectada</th>
                          <th>Deuda Total</th> {/* Nueva columna */}
                          <th>Saldo Capital</th> {/* Nueva columna */}
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {savedData.map((data, index) => (
                          <tr key={index}>
                            <td>{data.clientId}</td>
                            <td>{data.clientName}</td>
                            <td>{data.provinceCode}</td>
                            <td>{data.provinceName}</td>
                            <td>{data.cityCode}</td>
                            <td>{data.cityName}</td>
                            <td>{data.lat}</td>
                            <td>{data.lng}</td>
                            <td>{data.address}</td>
                            <td>{data.todayDate}</td>
                            <td>{data.projectedDate}</td>
                            <td>{data.debtTotal}</td>{" "}
                            {/* Mostrar deuda total */}
                            <td>{data.capitalBalance}</td>{" "}
                            {/* Mostrar saldo capital */}
                            <td>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fas fa-trash"></i>
                                <span className="ms-1">Eliminar</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>

       <button
  type="button"
  className="btn btn-primary btn-sm float-end mt-4"
  onClick={() => {
    if (savedData.length > 0) {
      // Mostrar el mensaje de éxito usando SweetAlert2
      Swal.fire({
        title: "Datos almacenados correctamente",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Ver proyección",
      }).then((result) => {
        if (result.isConfirmed) {
          // Aquí se guarda el JSON en JSONBin.io
          
          const apiKey = "$2a$10$f34lRzxEbMWe2LiW3KbPgu9JNEdrUnrN0hrV28wm02FXbnBfFQOsi"; // Tu API Key
          const url = "https://api.jsonbin.io/v3/b"; // Endpoint de JSONBin

          // Guardar los datos
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Master-Key": apiKey,
            },
            body: JSON.stringify(savedData), // Aquí se envía el array completo
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Error al guardar el JSON en JSONBin.io");
              }
            })
            .then((data) => {
              console.log("Datos almacenados en JSONBin.io:", data);
              Swal.fire("¡Guardado!", "Los datos fueron almacenados correctamente.", "success");
            })
            .catch((error) => {
              console.error("Error al guardar datos en JSONBin.io:", error);
              Swal.fire("Error", "Hubo un problema al guardar los datos.", "error");
            });
        } else if (result.isDismissed) {
          // Si se hizo clic en "Ver proyección"
          window.location.href = "/page-2"; // Redirigir a la ruta donde mostrarás la proyección
        }
      });
    } else {
      alert("No hay datos para guardar.");
    }
  }}
>
  <i className="bx bxs-save"></i>
  <span className="ms-2">Guardar proyección</span>
</button>;

        </div>
      </div>
    </div>
  );
};

export default Page1;
