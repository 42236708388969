import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/vendor/css/pages/page-auth.css"; // Asegúrate de tener los estilos de Select2
import BoyWithLaptop from "../../assets/img/illustrations/liceda.png";
import { fetchCedentes, login } from "./api"; // Importa la nueva función login
import Select2Component from "./Select2Component";

function Login() {
  const navigate = useNavigate();

  const [cedentes, setCedentes] = useState([]); // Estado para almacenar los datos de cedentes
  const [loading, setLoading] = useState(true); // Estado de carga para los cedentes
  const [usuario, setUsuario] = useState("0922287727"); // Estado para el usuario
  const [clave, setClave] = useState("0922287727"); // Estado para la contraseña
  const [selectedCedente, setSelectedCedente] = useState(""); // Estado para el cedente seleccionado

  // Llamada a la API para obtener los datos de los cedentes
  useEffect(() => {
    const fetchCedentesData = async () => {
      try {
        const data = await fetchCedentes();
        setCedentes(data); // Almacena los cedentes en el estado
        setSelectedCedente(data.length > 0 ? data[0].codigo : ""); // Establece el valor inicial del cedente seleccionado
        setLoading(false); // Cambia el estado de carga
      } catch (error) {
        console.error("Error fetching cedentes:", error);
        setLoading(false);
      }
    };

    fetchCedentesData();
  }, []);

  // Función para manejar el envío del formulario
  const handleSubmit = async () => {
    if (!usuario || !clave || !selectedCedente) {
      alert("Por favor ingresa todos los datos.");
      return;
    }

    try {
      // Llamada a la API de login
      const response = await login(usuario, clave, selectedCedente);

      // Verificamos si la respuesta es válida (si el arreglo tiene datos)
      if (response && Array.isArray(response) && response.length > 0) {
        // Si la respuesta contiene datos válidos, redirigimos al usuario
        const user = response[0];
        if (user && user.usuario) {
          // Login exitoso, redirigir al cliente
          navigate("/cliente", {
            state: {
              clientData: user, // Pasamos los datos del usuario
            },
          });
        } else {
          // Si el usuario no tiene datos válidos
          alert("Acceso denegado. Usuario o contraseña incorrectos.");
        }
      } else {
        // Si la respuesta es vacía o null
        alert("Acceso denegado. Usuario o contraseña incorrectos.");
      }
    } catch (error) {
      console.error("Error durante el login:", error);
      alert("Error al iniciar sesión.");
    }
  };

  return (
    <div className="authentication-wrapper authentication-cover">
      <div className="authentication-inner row m-0">
        {/* Imagen del Login */}
        <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center p-5">
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <img
              src={BoyWithLaptop}
              className="img-fluid mb-3"
              alt="Boy with rocket"
              width="150"
            />
            <p style={{ fontWeight: "bold", textAlign: "center" }}>LICEDA</p>
          </div>
        </div>

        {/* Formulario de Login */}
        <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
          <div className="w-px-400 mx-auto mt-12 pt-5">
            <form className="mb-6">
              <div className="mb-6">
                <label htmlFor="email" className="form-label">
                  Email or Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email or username"
                  autoFocus
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)} // Actualiza el estado del usuario
                />
              </div>
              <div className="mb-6 form-password-toggle">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                    value={clave}
                    onChange={(e) => setClave(e.target.value)} // Actualiza el estado de la contraseña
                  />
                  <span className="input-group-text cursor-pointer">
                    <i className="bx bx-hide"></i>
                  </span>
                </div>
              </div>

              {/* Select2 debajo del campo de contraseña */}
              <div className="mb-6">
                <label htmlFor="cedenteSelect" className="form-label">
                  Selecciona un Cedente
                </label>
                <Select2Component
                  id="cedenteSelect"
                  options={cedentes.map((cedente) => ({
                    value: cedente.codigo,
                    label: cedente.nombre,
                  }))}
                  placeholder="Seleccione un cedente"
                  value={selectedCedente} // Asegúrate de que se seleccione el valor por defecto
                  onChange={(e) => setSelectedCedente(e.target.value)} // Actualiza el estado del cedente seleccionado
                />
              </div>

              <button
                type="button"
                className="btn btn-primary d-grid w-100"
                onClick={handleSubmit} // Llama a la función handleSubmit
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
