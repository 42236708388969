import React, { useEffect } from "react";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";

const Select2Component = ({ id, options = [], placeholder, onChange }) => {
  useEffect(() => {
    console.log("Select2 options received:", options);

    const $select = $(`#${id}`);
    $select.select2({
      placeholder: placeholder || "Select an option",
      allowClear: true,
    });

    // Vincula el evento `change` al `onChange` si está definido
    if (onChange) {
      $select.on("change", onChange);
    }

    return () => {
      $select.off("change", onChange); // Desvincula el evento `change` al desmontar
      $select.select2("destroy");
    };
  }, [id, options, placeholder, onChange]);

  return (
    <select id={id} className="select2 form-select">
      {Array.isArray(options) &&
        options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
    </select>
  );
};

export default Select2Component;
