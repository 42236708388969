import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <aside
      id="layout-menu"
      className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0"
    >
      <div className="container-xxl d-flex h-100">
        <ul className="menu-inner py-1">
          {/* Página Cliente (selección de cliente) */}
          <li className="menu-item">
            <Link to="/cliente" className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-smile"></i>
              <div data-i18n="Page 1">Proyeccion de visitas</div>
            </Link>
          </li>
          {/* Página 2 */}
          <li className="menu-item">
            <Link to="/page-2" className="menu-link">
              <i className="menu-icon tf-icons bx bx-detail"></i>
              <div data-i18n="Page 2">Mapa</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/geomarca" className="menu-link">
              <i className="menu-icon tf-icons bx bx-detail"></i>
              <div data-i18n="Geo Marca">Geo-Marca</div>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
