import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Importa el hook useNavigate
import userAvatar from '../assets/img/avatars/login.png';

const Navbar = () => {
  const navigate = useNavigate(); // Inicializa el hook de navegación
  const [userName, setUserName] = useState(""); // Estado para almacenar el nombre del usuario

  useEffect(() => {
    // Obtener el nombre del usuario desde sessionStorage cuando el componente se monta
    const storedName = sessionStorage.getItem("nombre");
    if (storedName) {
      setUserName(storedName); // Si hay un nombre almacenado, lo guardamos en el estado
    }
  }, []);

// Función para manejar el logout
const handleLogout = () => {
  // Elimina todo el almacenamiento relacionado con la autenticación
  localStorage.clear(); // Elimina todos los items de localStorage
  sessionStorage.clear(); // Elimina todos los items de sessionStorage

  // Eliminar la data guardada en sessionStorage
sessionStorage.removeItem("savedData");

// Confirmación
alert("Datos eliminados correctamente.");

  // Redirigir al login después de hacer logout
  navigate("/"); // Redirige a la página de login
};


  return (
    <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme">
      <div className="container-xxl">
        <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
          <a href="/" className="app-brand-link gaps-2">
            <span className="app-brand-logo demo">
              <svg
                width="25"
                viewBox="0 0 25 42"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  {/* Añade aquí el contenido de las rutas SVG */}
                </defs>
                <g id="g-app-brand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Brand-Logo" transform="translate(-27.000000, -15.000000)">
                    {/* Más SVG */}
                  </g>
                </g>
              </svg>
            </span>
            <span className="app-brand-text demo menu-text fw-bold text-heading">Gestiones</span>
          </a>

          <button
            type="button"
            className="layout-menu-toggle menu-link text-large ms-auto d-xl-none"
          >
            <i className="d-flex align-items-center justify-content-center"></i>
          </button>
        </div>

        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
          <button type="button" className="nav-item nav-link px-0 me-xl-6">
            <i className="bx bx-menu bx-md"></i>
          </button>
        </div>

        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* User Dropdown */}
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <button
                type="button"
                className="nav-link dropdown-toggle hide-arrow p-0"
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  <img
                    src={userAvatar}
                    alt="User Avatar"
                    className="w-px-40 h-auto rounded-circle"
                  />
                </div>
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <div className="dropdown-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src={userAvatar}
                            alt="User Avatar"
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-0">{userName}</h6> {/* Muestra el nombre del usuario */}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown-divider my-1"></div>
                </li>
                <li>
                  <button type="button" className="dropdown-item">
                    <i className="bx bx-user bx-md me-3"></i>
                    <span>My Profile</span>
                  </button>
                </li>
                <li>
                  <a className="dropdown-item" href="/settings">
                    <i className="bx bx-cog bx-md me-3"></i>
                    <span>Settings</span>
                  </a>
                </li>
                <li>
                  <div className="dropdown-divider my-1"></div>
                </li>
                <li>
                  {/* Botón de Logout */}
                  <button type="button" className="dropdown-item" onClick={handleLogout}>
                    <i className="bx bx-power-off bx-md me-3"></i>
                    <span>Log Out</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
