import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Tu archivo CSS general
import App from './App'; // Componente principal de tu aplicación
import reportWebVitals from './reportWebVitals'; // Para medir rendimiento de tu app

// ** Importar estilos necesarios **
import 'bootstrap/dist/css/bootstrap.min.css'; // Estilos de Bootstrap
import 'boxicons/css/boxicons.min.css'; // Estilos de Boxicons

import './assets/css/core.css'; // Estilos Core del template
import './assets/css/theme-default.css'; // Estilos del tema
import './assets/css/demo.css'; // Estilos adicionales del template

// ** Importar scripts necesarios **
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Bootstrap + Popper.js
import 'perfect-scrollbar'; // Perfect Scrollbar (desplazamiento personalizado)
import 'hammerjs'; // Hammer.js (gestos táctiles)
 

// Crear el root principal para la aplicación React
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderizar el componente principal <App /> dentro del root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Medir rendimiento en tu aplicación (opcional)
reportWebVitals();
