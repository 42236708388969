import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GeoMarca = () => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null); // Referencia para evitar múltiples inicializaciones
  const [markersData, setMarkersData] = useState([]); // Estado para guardar las posiciones de los marcadores
  const polygonRef = useRef(null); // Referencia para el polígono
  const selectRef = useRef(null); // Referencia para el Select2
  const [nombreZona, setNombreZona] = useState(""); // Estado para el nombre de la zona
  const [zonasGuardadas, setZonasGuardadas] = useState([]); // Estado para las zonas guardadas
  const [nivelSeleccionado, setNivelSeleccionado] = useState("ALTO"); // Valor por defecto

  useEffect(() => {
    if (!window.google || !mapInstanceRef.current) return;
  
    const google = window.google;
  
    // Obtener el JSON padre desde localStorage
    const savedZones = JSON.parse(localStorage.getItem("zonasGuardadas")) || [];
  
    // Actualizar el estado con las zonas guardadas
    setZonasGuardadas(savedZones);
  
    // Dibujar cada zona en el mapa
    savedZones.forEach((zone) => {
      let fillColor;
      switch (zone.nivelSeleccionado) {
        case "ALTO":
          fillColor = "red";
          break;
        case "MEDIO":
          fillColor = "#FFB199"; // Color más claro para MEDIO
                    break;
        case "BAJO":
          fillColor = "green";
          break;
        default:
          fillColor = "gray";
      }
  
      // Dibujar el polígono para cada zona
      new google.maps.Polygon({
        paths: zone.coordenadas,
        strokeOpacity: 0,
        fillColor: fillColor,
        fillOpacity: 0.35,
        map: mapInstanceRef.current,
      });
    });
  
    console.log("Zonas guardadas pintadas en el mapa y sincronizadas en el estado:", savedZones);
  }, []); // Solo se ejecuta al montar el componente
  

  
  useEffect(() => {
    // Inicializar Select2
    if (selectRef.current) {
      $(selectRef.current).select2({
        placeholder: "Seleccione Nivel",
        width: "100%", // Para que ocupe todo el ancho
      }).on("change", function () {
        setNivelSeleccionado($(this).val()); // Actualizar el nivel seleccionado
      });
    }

    // Verificar si Google Maps API está disponible
    if (!window.google) {
      console.error("Google Maps API no está disponible. Asegúrate de cargar la librería.");
      return;
    }

    const google = window.google;

    // Verificar si el mapa ya ha sido inicializado
    if (!mapInstanceRef.current && mapRef.current) {
      // Inicializar el mapa
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: { lat: -2.15, lng: -79.88 }, // Coordenadas iniciales
        zoom: 12, // Nivel de zoom
      });

      // Escuchar el evento de clic en el mapa
      mapInstanceRef.current.addListener("click", (event) => {
        // Crear un marcador en la posición clickeada
        const marker = new google.maps.Marker({
          position: event.latLng,
          map: mapInstanceRef.current,
          title: "Nuevo marcador",
        });

        // Actualizar el estado con las nuevas coordenadas
        const newMarkerData = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setMarkersData((prevMarkers) => [...prevMarkers, newMarkerData]);

        // Agregar listener para eliminar el marcador al hacer clic
        marker.addListener("click", () => {
          marker.setMap(null); // Eliminar el marcador del mapa
          setMarkersData((prevMarkers) =>
            prevMarkers.filter(
              (m) => m.lat !== marker.getPosition().lat() || m.lng !== marker.getPosition().lng()
            )
          );
        });
      });
    }
  }, []);

  const handleEliminar = (index) => {
    const google = window.google;
  
    // Crear un array con las zonas restantes
    const updatedZones = zonasGuardadas.filter((_, i) => i !== index);
  
    // Actualizar el estado y localStorage
    setZonasGuardadas(updatedZones);
    localStorage.setItem("zonasGuardadas", JSON.stringify(updatedZones));
  
    // Limpiar el mapa antes de volver a pintar
    mapInstanceRef.current?.overlays?.forEach((overlay) => overlay.setMap(null));
    mapInstanceRef.current.overlays = [];
  
    // Volver a pintar las zonas restantes en el mapa
    updatedZones.forEach((zone) => {
      let fillColor;
      switch (zone.nivelSeleccionado) {
        case "ALTO":
          fillColor = "red";
          break;
        case "MEDIO":
          fillColor = "#FFB199"; // Naranja claro
          break;
        case "BAJO":
          fillColor = "green";
          break;
        default:
          fillColor = "gray";
      }
  
      // Dibujar el polígono correspondiente a la zona restante
      const polygon = new google.maps.Polygon({
        paths: zone.coordenadas,
        strokeOpacity: 0,
        fillColor: fillColor,
        fillOpacity: 0.35,
        map: mapInstanceRef.current,
      });
  
      // Guardar una referencia al polígono para futuras manipulaciones
      if (!mapInstanceRef.current.overlays) {
        mapInstanceRef.current.overlays = [];
      }
      mapInstanceRef.current.overlays.push(polygon);
    });
  
    // Mostrar un Toast de confirmación
   // toast.info("Zona eliminada correctamente.", { position: "bottom-right" });
  };
  

  
  const handleGuardar = () => {
    if (!window.google) return;
  
    const google = window.google;
  
    // Eliminar el polígono anterior si existe
    if (polygonRef.current) {
      polygonRef.current.setMap(null);
    }
  
    // Verificar si hay suficientes puntos para crear un polígono
    if (markersData.length > 2) {
      // Determinar el color según el nivel seleccionado
      let fillColor;
      switch (nivelSeleccionado) {
        case "ALTO":
          fillColor = "red";
          break;
        case "MEDIO":
          fillColor = "#FFB199"; // Color más claro para MEDIO
          break;
        case "BAJO":
          fillColor = "green";
          break;
        default:
          fillColor = "gray";
      }
  
      // Dibujar el polígono con los puntos actuales
      const polygon = new google.maps.Polygon({
        paths: markersData, // Usar solo los puntos actuales
        strokeOpacity: 0,
        fillColor: fillColor,
        fillOpacity: 0.35,
        map: mapInstanceRef.current,
      });
  
      polygonRef.current = polygon; // Guardar la referencia del polígono
  
      // Crear el nuevo registro
      const newZone = {
        nombreZona,
        nivelSeleccionado,
        coordenadas: markersData,
      };
  
      // Cargar el JSON padre desde localStorage o inicializarlo
      const existingData = JSON.parse(localStorage.getItem("zonasGuardadas")) || [];
  
      // Agregar el nuevo registro al JSON padre
      const updatedData = [...existingData, newZone];
  
      // Actualizar localStorage con el JSON padre
      localStorage.setItem("zonasGuardadas", JSON.stringify(updatedData));
  
      // Actualizar el estado con el JSON padre
      setZonasGuardadas(updatedData);
  
      // Mostrar Toast de éxito
      toast.success(
        <div>
          <strong>Nueva Zona Guardada:</strong>
          <pre>{JSON.stringify(newZone, null, 2)}</pre>
        </div>,
        { position: "bottom-right" }
      );
  
      console.log("JSON padre actualizado:", updatedData);
  
      // Limpiar marcadores para un nuevo registro
      clearMarkers();
    } else {
      // Toast de error si no hay suficientes puntos
      toast.error("Se necesitan al menos 3 puntos para crear un área cerrada.", {
        position: "bottom-right",
      });
    }
  };
  
  // Función para limpiar los marcadores del mapa
  const clearMarkers = () => {
    setMarkersData([]); // Limpiar los datos de marcadores
  };
  
  

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <h6>INGRESO DE GEOMARCAS</h6>
      <div className="row">
        {/* Columna izquierda con el input y datos dinámicos */}
        <div className="col-md-3">
          {/* Primer Card */}
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <label htmlFor="zonaInput" className="form-label">
                  Ingrese nombre de la zona
                </label>
                <input
                  type="text"
                  id="zonaInput"
                  className="form-control"
                  placeholder="Nombre de la zona"
                  value={nombreZona}
                  onChange={(e) => setNombreZona(e.target.value)}
                />
              </div>

              {/* Select2 Dropdown */}
              <div className="mb-3">
                <label htmlFor="nivelSelect" className="form-label">
                  Seleccione Nivel
                </label>
                <select
  id="nivelSelect"
  ref={selectRef}
  className="form-select"
  value={nivelSeleccionado} // Vincular el estado al select
  onChange={(e) => setNivelSeleccionado(e.target.value)} // Actualizar el estado al cambiar
>
  <option value="ALTO">ALTO</option>
  <option value="MEDIO">MEDIO</option>
  <option value="BAJO">BAJO</option>
</select>

              </div>

              <div className="d-none">
                <ul>
                  {markersData.map((marker, index) => (
                    <li key={index}>
                      {marker.lat.toFixed(6)}, {marker.lng.toFixed(6)}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Botón guardar */}
              <button className="btn btn-primary w-100 mt-3" onClick={handleGuardar}>
                Guardar
              </button>
            </div>
          </div>

{/* Segundo Card */}
<div className="card mt-3">
  <div className="card-body">
    <h5 className="card-title">Zonas Guardadas</h5>
    {zonasGuardadas.length === 0 ? (
      <p className="card-text">No hay zonas guardadas aún.</p>
    ) : (
      <ul className="list-group">
        {zonasGuardadas.map((zona, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <strong>{zona.nombreZona}</strong>
              <br />
              Nivel: {zona.nivelSeleccionado}
            </div>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleEliminar(index)}
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
    )}
  </div>
</div>


        </div>


        {/* Columna derecha con el mapa */}
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <div
                ref={mapRef}
                style={{
                  width: "100%",
                  height: "500px", // Altura del mapa
                  border: "1px solid #ccc", // Borde opcional
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoMarca;
