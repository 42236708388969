import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Asegúrate de importar useNavigate
import Select2Component from "./Select2Component";
import { fetchProvinces, fetchCities, fetchClientData } from "./api";
import DataTable from "react-data-table-component";

// Definición de las columnas del DataTable
const columns = [
  { name: "Cedente", selector: (row) => row.cedente, sortable: true },
  { name: "Identificación", selector: (row) => row.identificacion, sortable: true },
  { name: "Nombre", selector: (row) => row.nombre, sortable: true },
  { name: "Deuda Total", selector: (row) => parseFloat(row.deuda_total), sortable: true, right: true },
  { name: "Saldo Capital", selector: (row) => parseFloat(row.saldo_capital), sortable: true, right: true },
];

const Cliente = () => {
  const navigate = useNavigate(); // Inicializamos el hook de navegación
  const [clients, setClients] = useState([]); // Lista de clientes dinámicos
  const [loadingClients, setLoadingClients] = useState(true); // Estado de carga de clientes
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingProvinces, setLoadingProvinces] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState("T"); // Provincia seleccionada
  const [selectedCity, setSelectedCity] = useState("T"); // Ciudad seleccionada

  // Función para obtener datos de clientes desde la API
  const fetchClients = async (provincia = "T", ciudad = "T") => {
    try {
      setLoadingClients(true);
      const data = await fetchClientData(provincia, ciudad);
      if (Array.isArray(data) && Array.isArray(data[0])) {
        setClients(data[0]); // Extraer los datos del índice 0
      } else {
        console.error("Formato de datos inesperado:", data);
        setClients([]);
      }
    } catch (error) {
      console.error("Error al obtener los datos de clientes:", error);
    } finally {
      setLoadingClients(false);
    }
  };

  useEffect(() => {
    fetchClients(); // Llamada inicial
  }, []);

  // Cargar provincias
  useEffect(() => {
    const loadProvinces = async () => {
      try {
        const data = await fetchProvinces();
        const formattedProvinces = data.map((province) => ({
          value: province.identificacion,
          label: province.nombre,
        }));
        setProvinces(formattedProvinces);
      } catch (error) {
        console.error("Error fetching provinces:", error);
      } finally {
        setLoadingProvinces(false);
      }
    };

    loadProvinces();
  }, []);

  // Cargar ciudades basadas en la provincia seleccionada
  useEffect(() => {
    const loadCities = async () => {
      if (!selectedProvince) {
        setCities([]);
        return;
      }

      setLoadingCities(true);
      try {
        const data = await fetchCities(selectedProvince);
        const formattedCities = data.map((city) => ({
          value: city.identificacion,
          label: city.nombre,
        }));
        setCities(formattedCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      } finally {
        setLoadingCities(false);
      }
    };

    loadCities();
  }, [selectedProvince]);

  const handleRowClick = (rowData) => {
    const province = provinces.find((province) => province.value === selectedProvince) || {};
    const city = cities.find((city) => city.value === selectedCity) || {};

    const provinceCode = province.value || "No disponible";
    const provinceName = province.label || "No disponible";
    const cityCode = city.value || "No disponible";
    const cityName = city.label || "No disponible";

    // Redirigir a Page1 y pasar los datos como estado
    navigate("/page-1", {
      state: {
        clientData: rowData,
        provinceCode,
        provinceName,
        cityCode,
        cityName,
      },
    });
  };

  const handleProvinceChange = (e) => {
    const provinceValue = e.target.value;
    setSelectedProvince(provinceValue);

    if (provinceValue === "T") {
      setSelectedCity("T");
      fetchClients("T", "T");
    } else {
      fetchClients(provinceValue, selectedCity);
    }
  };

  const handleCityChange = (e) => {
    const cityValue = e.target.value;
    setSelectedCity(cityValue);

    if (selectedProvince === "T") {
      fetchClients("T", "T");
    } else {
      fetchClients(selectedProvince, cityValue);
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h5>INGRESO DE DATOS PARA PROYECCIÓN DE VISITAS</h5>
      <div className="card mb-6">
        <div className="card-body">
          {/* Formulario de selección */}
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="provincesSelect" className="form-label">
                Selecciona una Provincia
              </label>
              <Select2Component
                id="provincesSelect"
                options={provinces}
                placeholder="Escoge una provincia"
                onChange={handleProvinceChange} // Manejar cambio de provincia
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="citiesSelect" className="form-label">
                Selecciona una Ciudad
              </label>
              {loadingCities ? (
                <div>Cargando ciudades...</div>
              ) : (
                <Select2Component
                  id="citiesSelect"
                  options={cities}
                  placeholder="Escoge una ciudad"
                  onChange={handleCityChange} // Manejar cambio de ciudad
                />
              )}
            </div>
          </div>

          {/* Tabla de Clientes */}
          <h3 className="mb-4 mt-5">Lista de Clientes</h3>
          {loadingClients ? (
            <p>Cargando clientes...</p>
          ) : (
            <DataTable
              columns={columns}
              data={clients} // Datos dinámicos de la API
              pagination
              highlightOnHover
              striped
              persistTableHead
              onRowClicked={handleRowClick} // Maneja el clic en la fila
            />
          )}
        </div>
      </div>
      <style>
        {`
          /* Estilo para cambiar el cursor al puntero cuando se pasa sobre las filas de la tabla */
          .rdt_TableRow:hover {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

export default Cliente;
